import React from 'react';

function Terms() {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Terms of Service</h1>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg p-8 shadow-md space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Service Overview</h2>
            <p className="text-gray-600">
              behalf.me provides in-home and remote technical support services for senior citizens. Our services include device setup, troubleshooting, and digital safety education.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Service Appointments</h2>
            <p className="text-gray-600">
              We require 24 hours notice for cancellations. Our technicians will arrive within the scheduled time window. We reserve the right to refuse service if the environment is unsafe or if requested tasks fall outside our scope of services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Payment Terms</h2>
            <p className="text-gray-600">
              Payment is required at the time of service. We accept all major payment methods including UPI, credit cards, and digital wallets. Service rates will be communicated during booking.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. User Responsibilities</h2>
            <p className="text-gray-600">
              Clients must provide accurate information and a safe working environment. Access to necessary devices and accounts should be available during the service visit.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Service Guarantee</h2>
            <p className="text-gray-600">
              We strive to resolve all technical issues to the best of our ability. If you're not satisfied with our service, please contact us within 48 hours of service delivery.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Terms;