import React from 'react';
import SectionCTA from '../components/SectionCTA';

function About() {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">About behalf.me</h1>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg p-8 shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-6">
            We believe technology should be accessible to everyone, regardless of age. Our mission is to bridge the digital divide by providing personalized tech support to seniors, ensuring they can confidently navigate the digital world.
          </p>

          <h2 className="text-2xl font-semibold mb-4">Why Choose Us</h2>
          <ul className="space-y-4 text-gray-600 mb-6">
            <li>• Dedicated tech experts with experience helping seniors</li>
            <li>• Personalized, patient support at your convenience</li>
            <li>• Comprehensive solutions for all digital needs</li>
            <li>• Focus on building confidence and independence</li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">Our Approach</h2>
          <p className="text-gray-600 mb-6">
            We understand that every person has unique needs when it comes to technology. That's why we offer personalized, patient support in the comfort of your home. Our experts take the time to explain things clearly and ensure you're comfortable with your devices.
          </p>
        </div>
        <SectionCTA message="Schedule a Consultation" />
      </div>
    </div>
  );
}

export default About;