import React from 'react';
import { Check, Shield, HomeIcon, MessageSquare, HelpCircle, Phone } from "lucide-react";
import SectionCTA from '../components/SectionCTA';

function HomePage() {
  const handleBookVisit = () => {
    window.open('https://tally.so/r/nPX0rP', '_blank');
  };

  return (
    <>
      {/* Hero Section */}
        <section className="container mx-auto px-6 py-24 bg-[#F5F5F4]">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="md:w-1/2 z-10">
              <h1 className="text-5xl md:text-6xl font-bold leading-tight mb-6">
                Your Parents' Personal Tech Expert
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Because you can't always be there to help
              </p>
              <button 
                onClick={handleBookVisit}
                className="bg-black text-white hover:bg-gray-800 transition-colors px-8 py-4 rounded-md inline-flex items-center gap-2 font-medium"
              >
                Book a Home Visit
                <span className="ml-2">→</span>
              </button>
            </div>
            <div className="md:w-1/2">
              <div className="relative w-full h-[400px] md:h-[500px] rounded-xl overflow-hidden shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-black/10 to-transparent z-10" />
                <img
                  src="/images/behalf_hero_compressed.png"
                  alt="Senior using technology"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </section>

      {/* Sounds Familiar Section */}
        <section className="bg-[#FFFBF5] py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-16">
              Sounds Familiar?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: <MessageSquare className="w-12 h-12" />,
                  title: "Beta, WhatsApp is not working again!",
                  description: "No more lengthy phone calls trying to explain tech support to your parents. We'll handle it in person."
                },
                {
                  icon: <Shield className="w-12 h-12" />,
                  title: "Is this UPI request genuine?",
                  description: "Protect your parents from online scams. We'll teach them digital safety while solving their tech issues."
                },
                {
                  icon: <HelpCircle className="w-12 h-12" />,
                  title: "Can you come home and fix this?",
                  description: "Can't take time off work for tech support? Let us be your parents' go-to tech person."
                }
              ].map((card, index) => (
                <div 
                  key={index}
                  className="bg-white rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow"
                >
                  <div className="bg-yellow-50 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
                    <div className="text-yellow-500">
                      {card.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">{card.title}</h3>
                  <p className="text-gray-600 leading-relaxed text-center">{card.description}</p>
                </div>
              ))}
            </div>
            <SectionCTA message="Get Help With Your Tech Issues" />
          </div>
        </section>

      {/* Services Section */}
      {/* Services Section */}
<section className="bg-[#F5F5F4] py-20">
  <div className="container mx-auto px-6">
    <h2 className="text-4xl font-bold text-center mb-16">
      We Help With Daily Digital Challenges
    </h2>
    <div className="flex flex-col md:flex-row gap-8">
      {[
        {
          icon: Phone,
          title: "Everyday Tech Help",
          subtitle: "Just a phone call away",
          services: [
            "Quick questions about apps and devices",
            "Help with online bookings (travel, movies, etc.)",
            "Support for daily digital tasks",
            "Basic app troubleshooting",
            "Remote support available"
          ]
        },
        {
          icon: HomeIcon,
          title: "Dedicated Support Services",
          subtitle: "At your doorstep",
          services: [
            "In-person assistance for complex tasks",
            "Banking and financial app setup",
            "Government services navigation",
            "Device setup and personalization",
            "Account management assistance"
          ]
        },
        {
          icon: Shield,
          title: "Digital Safety Education",
          subtitle: "Learn to stay safe online",
          services: [
            "Understanding common online scams",
            "Safe online banking practices",
            "Identifying suspicious messages",
            "Smart device privacy settings",
            "Family sharing best practices"
          ]
        }
      ].map((service, index) => (
        <div key={index} className="bg-white rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow flex-1">
          <div className="text-center mb-8">
            <service.icon className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
            <p className="text-yellow-500 text-sm font-medium">{service.subtitle}</p>
          </div>
          <div className="space-y-4">
            {service.services.map((item, i) => (
              <div key={i} className="flex items-start gap-3">
                <Check className="w-4 h-4 text-yellow-500 mt-1.5 flex-shrink-0" />
                <span className="text-gray-600">{item}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    <SectionCTA message="Schedule Your Tech Support Visit" />
  </div>
</section>

      {/* Peace of Mind Section */}
      <section className="bg-[#FFFBF5] py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-16">
            Peace of Mind for You
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                number: "1",
                title: "Save Time",
                description: "No more long explanation calls or weekend visits for tech support"
              },
              {
                number: "2",
                title: "Stay Safe",
                description: "Protect your parents from digital frauds and scams"
              },
              {
                number: "3",
                title: "Be Independent",
                description: "Help your parents become digitally confident"
              }
            ].map((card, index) => (
              <div 
                key={index}
                className="bg-white rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="w-12 h-12 bg-yellow-500 rounded-full flex items-center justify-center mx-auto mb-6 text-white font-bold text-xl">
                  {card.number}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-center">{card.title}</h3>
                <p className="text-gray-600 leading-relaxed text-center">{card.description}</p>
              </div>
            ))}
          </div>
          <SectionCTA message="Book Your First Visit Today" />
        </div>
      </section>
    </>
  );
}

export default HomePage;