import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <header className="bg-gradient-to-br from-[#FFF4E6] to-[#FFECD1]">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <span className="text-2xl font-bold text-black">behalf.me</span>
        </Link>
        
        <nav className="flex items-center">
          <Link 
            to="/about" 
            className={`px-4 py-2 text-sm font-medium transition-colors ${
              isActive('/about') ? 'text-yellow-500' : 'hover:text-yellow-500'
            }`}
          >
            About
          </Link>

          {/* Pricing Section */}
          {/* <Link 
            to="/pricing" 
            className={`px-4 py-2 text-sm font-medium transition-colors ${
              isActive('/pricing') ? 'text-yellow-500' : 'hover:text-yellow-500'
            }`}
          >
            Pricing
          </Link> */}
          
          <Link 
            to="/contact" 
            className={`px-4 py-2 text-sm font-medium transition-colors ${
              isActive('/contact') ? 'text-yellow-500' : 'hover:text-yellow-500'
            }`}
          >
            Contact
          </Link>

          {/* Career Section */}
          {/* <Link 
            to="/careers" 
            className={`px-4 py-2 text-sm font-medium transition-colors ${
              isActive('/careers') ? 'text-yellow-500' : 'hover:text-yellow-500'
            }`}
          >
            Careers
          </Link> */}
        </nav>
      </div>
    </header>
  );
}

export default Header;