import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Check, Shield, HomeIcon, MessageSquare, HelpCircle, Phone } from "lucide-react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import pages
// Pages
import Home from './Pages/Home';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import About from './Pages/About';
import Contact from './Pages/Contact';
// import Careers from './Pages/Careers'; To enable Careers' page, un-comment this and line 32 and careers section in header.js
// import Pricing from './Pages/Pricing'; To enable Pricing page, un-comment this and line 30 and pricing section in header.js

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import SectionCTA from './components/SectionCTA';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen bg-[#F5F5F4]">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/careers" element={<Careers />} /> */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;