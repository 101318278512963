import React from 'react';

function Privacy() {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg p-8 shadow-md space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
            <p className="text-gray-600">
              We collect basic contact information, service addresses, and technical support requirements to provide our services. This includes names, phone numbers, email addresses, and home addresses.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
            <p className="text-gray-600">
              Your information is used solely for providing tech support services, scheduling appointments, and communicating about service delivery. We do not sell or share your data with third parties.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Data Security</h2>
            <p className="text-gray-600">
              We implement appropriate security measures to protect your personal information. Our technicians are trained in data privacy and security practices.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
            <p className="text-gray-600">
              You have the right to access, correct, or delete your personal information. Contact us to exercise these rights or discuss privacy concerns.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Privacy;