import React from 'react';

function SectionCTA({ message = "Book a Home Visit" }) {
  const handleBookVisit = () => {
    window.open('https://tally.so/r/nPX0rP', '_blank');
  };

  return (
    <div className="mt-12 text-center">
      <button 
        onClick={handleBookVisit}
        className="bg-black text-white hover:bg-gray-800 transition-colors inline-flex items-center gap-2 px-6 py-3 rounded"
      >
        <span>{message}</span>
        <span className="ml-2">→</span>
      </button>
    </div>
  );
}

export default SectionCTA;