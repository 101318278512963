import React from 'react';

function Contact() {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Contact Us</h1>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg p-8 shadow-md">
          <div className="text-gray-600 space-y-4">
            <p>
              <span className="font-medium">Phone: </span>
              <a href="tel:+918879901887" className="hover:text-yellow-500">
                +91-887-9901-887
              </a>
            </p>
            <p>
              <span className="font-medium">Email: </span>
              <a href="mailto:saurabhkhante@gmail.com" className="hover:text-yellow-500">
                saurabhkhante[at]gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;